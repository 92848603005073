import { ThemeOptions } from "@mui/material";

const palette: Exclude<ThemeOptions["palette"], undefined> = {
  background: {
    default: "#371CA1",
    paper: "#FFFFFF",
  },
  primary: {
    main: "#3ed490",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#371CA1",
    light: "#371CA1",
    contrastText: "#ffffff",
  },
  error: {
    main: "#8E0923",
  },
  info: {
    main: "#371CA1",
  },
  action: {
    active: "#3ed490",
  },
  text: {
    primary: "#371CA1",
    secondary: "#121212",
  },
};

export default palette;
